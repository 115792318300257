/* body{
    background:#eee;
} */

.profile-pg-container{
    background-color: #002848;
    width: 250px;
}
.profile-pg-card{
    border:none;
    background-color: #002848;
    position:relative;
    overflow:hidden;
    border-radius:12px;
    cursor:pointer;
}

.profile-pg-card:hover::after{
    transform:scaleY(1);
}

.profile-pg-buttons{
       border-radius: 50px;
       color:#fff;
       height:40px;
       background-color: #143957 !important;
       border-color: #143957 !important;
}

.profile-pg-name{
    color: #fff;
}