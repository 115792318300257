.bottom-right-rounded {
    border-radius: 0 0 90px;
    /* border: 1px solid #000 !important; */
    overflow: hidden;
}



.aboutus-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    /* border-radius: 15px 15px 50px; */
}

@media (max-width: 768px) {
    .bottom-right-rounded {
        border-radius: 0 0 35px !important;
        /* border: 1px solid #000 !important; */
        overflow: hidden;
    }
    .aboutus-title-text h3{
        font-size: 22px !important;
    }
}