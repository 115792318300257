/* Custom card styles */
.card-content {
    border-radius: 18px 18px 18px 80px; /* Rounded corners for the card */
    /* width: 500px; */
    height: 522px;
  }

  /* You can add more custom styles here to match the exact design */
  .img-fuelcard{
    border-radius: 18px 18px 80px 18px;
    padding-bottom: 12px;
    /* width: 500px; */
  }

  .card-logo-header{
    font-size: 24px ;
    margin-left: 6px;
  }

  .btn-fuel-discount{
    background-color: rgba(251, 110, 44, 1) !important;
    border-radius: 22px;
    color: white;
    /* font-weight: 600; */
  }