/* .giveaway-primary-text * {
    color: #002848 !important;
}

.giveaway-bg-blue,
.giveaway-bg-blue-wt {
    background-color: #002848 !important;
}


.giveaway-bg-blue-wt p,
.giveaway-bg-blue-wt h1 {
    color: #fff !important;
}

.giveaway-bg-blue p,
.giveaway-bg-blue h1 {
    color: #d0e518 !important;
}

.giveaway-text-green {
    color: #d0e518 !important;
}

.giveaway-entry-purple {
    background-color: #be85e5 !important;
}

.giveaway-subscription-entry-default-container p,
.giveaway-subscription-entry-default-container h1 {
    color: #fff !important;
}
.giveaway-faq-accordian-button.collapsed {
    background-color: #002848;
    color: #fff !important;
}
.giveaway-tabs {
    border-color: #d0e518 !important;
}
.giveaway-btn-login {
    background-color: #d0e518 !important;
    color: #002848 !important;
}

.giveaway-btn-login:hover {
    background-color: rgb(208, 229, 24, 0.7) !important;
    color: #002848 !important;
} */


@media only screen and (max-width: 768px) {
    .giveaway-payment-freq-text {
        font-size: 9px;
        margin-bottom: 0px;
        font-weight: 500;
    }

    .giveaway-entry-container-main {
        border-radius: 100px 0;
    }

    .giveaway-entry-count-text {
        /* line-height: 0px; */
         /* font-size: 6vw !important;; */
    }

    .giveaway-entry-count-text h1 {
        /* font-size: 6vw !important;; */
    }
    .giveaway-entry-count-text-sub {
        line-height: 20px;
    }

    .giveaway-entry-count-text-sub h1 {
        margin-bottom: 0px;
    }

    .giveaway-entry-desc {
        font-size: 14px;
        line-height: 18px;
        margin: 0;
        font-weight: 400;
    }

    .giveaway-entry-btn {
        font-size: 13px;
    }

    .giveaway-entry-container {
        position: relative;
        overflow: hidden;
        padding-left: 45px !important;
        padding-right: 30px !important;
    }

    .giveaway-subscription-entry {
        width: 35px;
        padding-right: 5px;
        position: absolute;
        left: 0;
        top:0;
        text-align: center;
        background-color: #dee3e6;
        color: #002848;
        font-weight: 500;
        height: 100%;
        writing-mode: tb-rl;
        transform: rotate(-180deg);
    }

    .giveaway-subscription-entry-default {
        width: 35px;
        padding-right: 5px;
        position: absolute;
        left: 0;
        top:0;
        text-align: center;
        background-color: #1b3f5c;
        color: #fff !important;
        font-weight: 500;
        height: 100%;
        writing-mode: tb-rl;
        transform: rotate(-180deg);
    }



    .giveaway-time-box {
        height: 100px;
        /* width: 100px;   1b3f5c */
        display: inline-block;
        margin: 0 10px 0 10px;
    }

    .giveaway-time-box>div {
        height: 55px;
        width: 59px;
        text-align: center;
        /* vertical-align: middle; */
        display: block;
        box-shadow: 0px 0px 5px 0px #ccc;
        border-radius: 9px;
        font-size: 35px;
        font-weight: bold;
        margin: auto;
    }

    .giveaway-time-box-colon {
        height: 50px;
        vertical-align: top;
        margin-top: -4px;
        font-size: 50px;
        display: inline-block;
    }

    .guveaway-premium-box {
        background-color: #eee;
        width: max-content;
         padding: 2rem!important; 
         font-size: 15px;
    }

    .giveaway-faq-container {
        width: 100%;
    }


    .giveaway-member-profile-pic{
        width: 100px;
    }
    .giveaway-member-container {
        width: 100%;
        margin: auto;
    }

    .giveaway-faq-accordian-button.collapsed::after {
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 10px solid #fff;
    }

    .giveaway-faq-accordian-button::after {
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 10px solid #fff;
    }


    .giveaway-secure-paddlock {
        color: #fff !important;
        background-color: #002848;
        padding: 5px;
        border-radius: 50%;
        width: 34px;
        display: inline-block;
        padding-left: 3px;
    }

    .giveaway-payment-options {
        width: 45px;
        height: 30px;
        background-repeat: no-repeat;
        background-size: 80%;
        background-color: #fff;
        margin: 5px;
        border-radius: 5px;
        background-position: center center;
        display: inline-block;
    }

    .giveaway-entry-header {
        width: auto;
    }



}

@media only screen and (max-width: 500px) {
    .giveaway-entry-count-text{
         font-size: 3.5vw !important;
    }
    .giveaway-entry-count-text h1{
        font-size: 5vw !important;
   }
   .giveaway-entry-btn {
    font-size: 3vw !important;
}
}

@media only screen and (max-width: 375px) {
    .giveaway-entry-count-text{
         font-size: 3.5vw !important;
    }
    .giveaway-entry-count-text h1{
        font-size: 5vw !important;
   }
   .giveaway-entry-desc{
    font-size: 3vw;
   }
}

/* 
.giveaway-page ::-ms-input-placeholder {
 
    text-align: center;
}

.giveaway-page ::placeholder {
    text-align: center;
}
.giveaway-page #cboxState > option:checked{
    text-align: center !important;
}
.giveaway-page #cboxState:active > option{
    text-align: left !important;
} */