:root {
  --bs-blue: #FB6E2C;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #F4F8FB;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #FB6E2C !important;
  /*0d6efd;*/
  --bs-secondary: #002848 !important;
  --bs-success: #BAF8C2;
  --bs-info: #d0e518;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 251, 110, 44 !important;
  --bs-secondary-rgb: 0, 40, 72 !important;
  --bs-success-rgb: 186, 248, 194 !important;
  --bs-info-rgb: 208, 229, 24 !important;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
}



* {
  font-family: 'Poppins', sans-serif !important;
}

body {
  margin: 0;
  /* font-family: 'Poppins' !important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

footer {
  margin: auto 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.isn-title {
  font-size: 26px !important;
  font-weight: bold;
}

.isn-sub-title {
  font-size: 28px !important;
  font-weight: bold;
}

.isn-sub2-title {
  font-size: 18px !important;
  font-weight: bold;
}

.isn-thumb-title {
  font-size: 13px !important;
}

.isn-thumb-desc {
  font-size: 11px !important;
}

.isn-thumb-pill {
  color: #002848 !important;
  font-size: 10px !important;
  border: 2px solid #FB6E2C !important;
  background-color: rgb(251, 110, 44, 0.7);
  font-weight: normal !important;
}

.isn-thumb-pill-green {
  background-color: rgb(208, 229, 24, 0.7) !important;
  border: 2px solid #D0E518 !important;
  /* font-size: 10px !important; */
}

.isn-btn-premium-primary{
  background-color: #D0E518 !important;
  color: #002848 !important;
}

.isn-thumb-pill-offer {
  color: #002848 !important;
  font-size: 10px !important;
  background-color: #FB6E2C;
}

.isn-thumb-pill-green-offer {
  background-color: #D0E518 !important;
  /* font-size: 10px !important; */
}

.isn-thumb-merchant {
  font-size: 14px !important;
}

.isn-fs-10 {
  font-size: 10px !important;
}

.isn-fs-11 {
  font-size: 11px !important;
}

.isn-fs-12 {
  font-size: 12px !important;
}

.isn-fs-13 {
  font-size: 13px !important;
}

.isn-fs-14 {
  font-size: 14px !important;
}

.isn-fs-16 {
  font-size: 16px !important;
}

.isn-fs-17 {
  font-size: 17px !important;
}

.isn-fs-18 {
  font-size: 18px !important;
}

.isn-fs-21 {
  font-size: 21px !important;
}

.isn-fs-23 {
  font-size: 23px !important;
}

.isn-fs-24 {
  font-size: 24px !important;
}

.isn-fs-30 {
  font-size: 30px !important;
}


.isn-curve-bottom-left {
  border-bottom-left-radius: 80px !important;
}

.isn-curve-bottom-right {
  border-bottom-right-radius: 80px !important;
}

.isn-bradius {
  border-radius: 18px;
}

.isn-page-bg {
  background-color: #F4F8FB !important;
}

.isn-text-normal {
  text-decoration: none;
  color: #FB6E2C;
}

.isn-text-decoration-none {
  text-decoration: none;
}

.isn-full-overlay {
  background-color: rgb(0 0 0 / 80%);
  position: relative;
  width: 100%;
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9000;
}

.isn-content-center {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.isn-fuel-text-blue {
  color: #002848;
}

.isn-font-color-white {
  color: white !important;
}

.isn-font-color-white:hover {
  background-color: #143957 !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.isn-hide-scroll::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.isn-hide-scroll {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}

.isn-carousel-control-next,
.isn-carousel-control-prev {
  width: auto !important;
}