.giveaway-primary-text * {
    color: #002848 !important;
}

.giveaway-bg-blue, .giveaway-bg-blue-wt{
    background-color: #002848 !important;
}


.giveaway-bg-blue-wt p,
.giveaway-bg-blue-wt h1 {
    color: #fff !important;
}

.giveaway-bg-blue p,
.giveaway-bg-blue h1 {
    color: #d0e518 !important;
}

.giveaway-text-green {
    color: #d0e518 !important;
}

.giveaway-entry-purple {
    background-color: #be85e5 !important;
}

.giveaway-payment-freq-text {
    font-size: 10px;
    margin-bottom: 0px;
    font-weight: 500;
}

.giveaway-entry-container-main{
    border-radius: 150px 0;
}

.giveaway-entry-desc{
    font-size: 17px;
    line-height: 20px;
    margin: 0;
    font-weight: 500;
}

.giveaway-entry-btn{
    font-size: 16px;
}
.giveaway-entry-container {
    position: relative;
    overflow: hidden;
    padding-left: 55px !important;
    padding-right: 30px !important;
}

.giveaway-subscription-entry {
    width: 40px;
    padding-right: 8px;
    position: absolute;
    left: 0;
    top:0;
    text-align: center;
    background-color: #dee3e6;
    color: #002848;
    font-weight: 500;
    height: 100%;
    writing-mode: tb-rl;
    transform: rotate(-180deg);
}

.giveaway-subscription-entry-default {
    width: 40px;
    padding-right: 8px;
    position: absolute;
    left: 0;
    top:0;
    text-align: center;
    background-color: #1b3f5c;
    color: #fff !important;
    font-weight: 500;
    height: 100%;
    writing-mode: tb-rl;
    transform: rotate(-180deg);
}

.giveaway-subscription-entry-default-container p,
.giveaway-subscription-entry-default-container h1,
.giveaway-subscription-entry-default-container span,
.giveaway-subscription-entry-default-container strike {
    color: #fff !important;
}
    
.giveaway-entry-count-text{
    /* padding-top: 22px !important; */
    font-family: 'Poppins' !important;
    line-height: 13px;
}
.giveaway-time-box {
    height: 100px;
    /* width: 100px;   1b3f5c */
    display: inline-block;
    margin: 0 10px 0 10px;
}

.giveaway-time-box>div {
    height: 100px;
    width: 150px;
    text-align: center;
    vertical-align: middle;
    display: table-cell;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 9px;
    font-size: 55px;
    font-weight: bold;
}

.giveaway-time-box-colon {
    height: 50px;
    vertical-align: top;
    margin-top: 10px;
    font-size: 60px;
    display: inline-block;
}
.giveaway-member-profile-pic{
    width: 150px;
}
.giveaway-member-container{
    width: 75%;
    margin: auto;
}
.guveaway-premium-box {
    background-color: #eee;
    width: max-content;
    font-weight: 500;
     font-size: 20px;
}


.giveaway-entry-header{
    width: 50%;
}

.giveaway-faq-container{
    width: 75%;
}

.giveaway-faq-accordian-button.collapsed {
    background-color: #002848;
    color: #fff !important;
}

.giveaway-faq-accordian-button.collapsed::after {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 10px solid #fff;
}

.giveaway-faq-accordian-button::after {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 10px solid #fff;
}

.giveaway-tabs {
    border-color: #d0e518 !important;
}

.giveaway-secure-paddlock {
    color: #fff !important;
    background-color: #002848;
    padding: 5px;
    border-radius: 50%;
    width: 34px;
    display: inline-block;
    padding-left: 3px;
}

.giveaway-payment-options {
    width: 75px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: unset;
    background-color: #fff;
    margin: 5px;
    border-radius: 5px;
    background-position: center center;
    display: inline-block;
}

.giveaway-btn-login {
    background-color: #d0e518 !important;
    color: #002848 !important;
}

.giveaway-btn-login:hover {
    background-color: rgb(208, 229, 24, 0.7) !important;
    color: #002848 !important;
}
.previous-card-img{
    object-fit: cover;
}

.dg-md-fs{
    font-size: 1.12rem;
}
/* 
.giveaway-page ::-ms-input-placeholder {
 
    text-align: center;
}

.giveaway-page ::placeholder {
    text-align: center;
}
.giveaway-page #cboxState > option:checked{
    text-align: center !important;
}
.giveaway-page #cboxState:active > option{
    text-align: left !important;
} */