.how-it-woks-overlay{
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 15px 15px 50px;
}

.how-it-works-form{
    border-color: rgb(208, 229, 24);
}

.how-it-works-top-60{
    top: 70% !important;
}

.how-it-works-title{
    background-color: #D0E518;
    border-radius: 0 18px 80px 0;
    text-align: center;
    font-size: 45px;
    font-weight: bold;
    width: 32.4%;
}

.how-it-works-email{
    margin-top: 3%;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

.how-it-works-email-submit{
    background-color: rgb(208, 229, 24);
    width: 130px;
    height: 40px;
    border: 0px solid;
    border-radius: 50px;
    text-align: center;
}