.btn-payment-confirm {
    background-color: #D0E518;
    --bs-btn-hover-bg: #D0E518;
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    color: var(--bs-secondary-rgb);
    width: 108px;
    height: 40px;
    margin-top: 25px;
}

.p-FieldLabel{
    display: none !important;
}

.payment-element{
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-rgb),var(--bs-bg-opacity))!important;
    padding: 35px;
}

.payment-header{
    color: #D0E518 !important;
    font-size: 20px;
    font-weight: 600;
}

.payment-description{
    color: #D0E518 !important;
}

.payment-value{
    color: #D0E518 !important;
}

.payment-message{
    color: red;
}