.isn-prof-selected-tab {
    background-color: #FFF6F2;
    /* border-right: 3px solid #FB6E2C !important; */
    color: #FB6E2C !important;
}

.isn-prof-selected-tab a {
    color: #FB6E2C !important;
}

.isn-prof-tab {
    color: #000;
    padding: 5px 5px 5px 5px;
}
.isn-prof-tab a{
    color: #000;

}
.isn-prof-tab:hover{
    
    background-color: #FFF6F2;
    padding: 5px 5px 5px 5px;
}

.btn-withdraw{
    width: 133px;
    height: 32px;
    text-align: center;
    padding-top: 10px;
}