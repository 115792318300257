#fixed-categories {
  padding-top: 15px;
  cursor: pointer;
  background-color: #f8f9fa !important;
  z-index: 10 !important;
  position: absolute !important;
}

.scrollable-links {
  display: flex;
  overflow-x: auto;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
  font-size: 13px !important;
  text-wrap: nowrap !important;
  font-weight: 500 !important;
  color: #043963;
}

@media (max-width: 995px) {
  .scrollable-links {
    display: flex;
    /* Use flexbox to line up items horizontally */
    flex-wrap: nowrap;
    /* Prevents flex items from wrapping */
    overflow-x: auto;
    /* Enables horizontal scrolling */
    overflow-y: hidden;
    /* Hides vertical scrollbar */
    width: 100%;
    white-space: nowrap !important;
    /* Makes sure the container takes full width of its parent */
  }

  .scrollable-links .nav-item {
    flex: 0 0 auto;
    /* Flex children do not grow or shrink but are sized by their content */
  }
}

.megamenu {
  padding: 1rem;
}

@media all and (min-width: 992px) {
  .has-megamenu {
    position: static !important;
  }

  .megamenu {
    left: 0;
    right: 0;
    width: 100%;
    margin-top: 0;
  }
}

.dropdown-item {
  padding-bottom: 10px;
  padding-top: 10px;
  overflow: hidden;
  font-size: 0.813rem;
  color: #002848;
}

.dropdown-item:active {
  background-color: #002848 !important;
}

#cat-name {
  margin-left: 5%;
}

.c-cat-name {
  margin-left: 20px;
}

#cat-img {
  height: 25px;
  width: 25px;
}

.dropdown-item:hover {
  color: #fb6e2c;
  border-radius: 5px;
}

.dropdown-toggle:after {
  content: none !important;
}

/* Category images */

.c-cat-img-1 {
  background-image: url("/public/categories_blue/beauty_personalcare_blue.svg");
}

.dropdown-item:hover .c-cat-img-1 {
  background-image: url("/public/categories_orange/beauty_personalcare_orange.svg");
}

.c-cat-img-2 {
  background-image: url("/public/categories_blue/book_flowers_gift_blue.svg");
}

.dropdown-item:hover .c-cat-img-2 {
  background-image: url("/public/categories_orange/book_flowers_gift_orange.svg");
}

.c-cat-img-3 {
  background-image: url("/public/categories_blue/department_store_blue.svg");
}

.dropdown-item:hover .c-cat-img-3 {
  background-image: url("/public/categories_orange/department_store_orange.svg");
}

.c-cat-img-4 {
  background-image: url("/public/categories_blue/electronics_accessories_blue.svg");
}

.dropdown-item:hover .c-cat-img-4 {
  background-image: url("/public/categories_orange/electronics_accessories_orange.svg");
}

.c-cat-img-5 {
  background-image: url("/public/categories_blue/entertainment_blue.svg");
}

.dropdown-item:hover .c-cat-img-5 {
  background-image: url("/public/categories_orange/entertainment_orange.svg");
}

.c-cat-img-6 {
  background-image: url("/public/categories_blue/clothing_acccessories_blue.svg");
}

.dropdown-item:hover .c-cat-img-6 {
  background-image: url("/public/categories_orange/clothing_acccessories_orange.svg");
}

.c-cat-img-7 {
  background-image: url("/public/categories_blue/shoes_blue.svg");
}

.dropdown-item:hover .c-cat-img-7 {
  background-image: url("/public/categories_orange/shoes_orange.svg");
}

.c-cat-img-8 {
  background-image: url("/public/categories_blue/health_wellness_blue.svg");
}

.dropdown-item:hover .c-cat-img-8 {
  background-image: url("/public/categories_orange/health_wellness_orange.svg");
}

.c-cat-img-9 {
  background-image: url("/public/categories_blue/hobbies_games_blue.svg");
}

.dropdown-item:hover .c-cat-img-9 {
  background-image: url("/public/categories_orange/hobbies_games_orange.svg");
}

.c-cat-img-10 {
  background-image: url("/public/categories_blue/home_living_garden_blue.svg");
}

.dropdown-item:hover .c-cat-img-10 {
  background-image: url("/public/categories_orange/home_living_garden_orange.svg");
}

.c-cat-img-11 {
  background-image: url("/public/categories_blue/jewellery_watches_blue.svg");
}

.dropdown-item:hover .c-cat-img-11 {
  background-image: url("/public/categories_orange/jewellery_watches_orange.svg");
}

.c-cat-img-12 {
  background-image: url("/public/categories_blue/marketplace_blue.svg");
}

.dropdown-item:hover .c-cat-img-12 {
  background-image: url("/public/categories_orange/marketplace_orange.svg");
}

.c-cat-img-13 {
  background-image: url("/public/categories_blue/others_blue.svg");
}

.dropdown-item:hover .c-cat-img-13 {
  background-image: url("/public/categories_orange/others_orange.svg");
}

.c-cat-img-14 {
  background-image: url("/public/categories_blue/kids_babies_toys_blue.svg");
}

.dropdown-item:hover .c-cat-img-14 {
  background-image: url("/public/categories_orange/kids_babies_toys_orange.svg");
}

.c-cat-img-15 {
  background-image: url("/public/categories_blue/food_drinks_blue.svg");
}

.dropdown-item:hover .c-cat-img-15 {
  background-image: url("/public/categories_orange/food_drinks_orange.svg");
}

.c-cat-img-16 {
  background-image: url("/public/categories_blue/adult_blue.svg");
}

.dropdown-item:hover .c-cat-img-16 {
  background-image: url("/public/categories_orange/adult_orange.svg");
}

.c-cat-img-17 {
  background-image: url("/public/categories_blue/sports_fitness_blue.svg");
}

.dropdown-item:hover .c-cat-img-17 {
  background-image: url("/public/categories_orange/sports_fitness_orange.svg");
}

.c-cat-img-18 {
  background-image: url("/public/categories_blue/groceries_pets_blue.svg");
}

.dropdown-item:hover .c-cat-img-18 {
  background-image: url("/public/categories_orange/groceries_pets_orange.svg");
}

.c-cat-img-19 {
  background-image: url("/public/categories_blue/travel_blue.svg");
}

.dropdown-item:hover .c-cat-img-19 {
  background-image: url("/public/categories_orange/travel_orange.svg");
}

.cat-img {
  background-repeat: no-repeat;
  height: 25px;
  width: 25px;
  background-size: 100% 100%;
  display: inline-block;
  vertical-align: middle;
  background-position: center;
}