.isn-header-nav-link {
  padding-left: 1rem !important;
  padding-right: 0 !important;
}

.ph-breadCrumb {
  color: #FB6E2C;
}

.profile-dropdown-menu {
  left: 70% !important;
  background-color: #002848;
  top: 95% !important;
}

.upgrade-bar {
  font-size: 16px !important;
}

@media (min-width: 1628px) {
  .profile-dropdown-menu {
    left: 68% !important;
  }
}

@media (min-width: 1790px) {
  .profile-dropdown-menu {
    left: 66% !important;
  }
}


@media (max-width: 441px) {
  .header-img {
    width: 120px !important;
  }
}

@media (max-width: 376px) {
  .upgrade-bar {
    font-size: 14px !important;
  }
}