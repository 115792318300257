.modal-content{
    border-radius: 20px;
    
}
.sub-cancle-modal-header{
    padding: 0px 12px 0px 10px;
    border-bottom: 0px;
}

.sub-cancle-title{
    color: #5A4394;
    text-align: left;
    padding-left: 15px;
    font-size: 24px;
    font-weight: 500;
    margin-top: 15px;
}

.sub-cancle-content{
    font-size: 13px;
    line-height: 24px;
    color: #002848;
    padding: 0px 15px 0px 15px;
    text-align: left;
}

.sub-cancle-content-ul{
    line-height: 22px !important;
}

.sub-cancle-footer-price{
    font-size: 18px;
    color: #002848;
    line-height: 22px;
    text-align: left;
    float: left;
}

.modal-footer{
    border-top: 0px;
}

.modal-footer{
    border-top: 0px;
    justify-content: left;
}

.sub-cancle-upgrade{
    background-color: #28D294;
    --bs-btn-hover-bg:#28D294;
    border-radius: 41px;
    height: 46px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    color: #FFFFFF;
}

.sub-cancle-upgrade:hover {
    background-color: #28D294 !important;
    color: #FFFFFF;
} 

.sub-cancle-skip{
    border-radius: 41px;
    height: 46px;
    margin-left: 15px;
    background-color: #E9E9E9;
    --bs-btn-hover-bg:#E9E9E9;
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    color: #9F9F9F;
    --bs-btn-hover-color:#9F9F9F;
}

.sub-cancle-skip:hover {
    background-color: #E9E9E9 !important;
    color: #9F9F9F;
}

.sub-cancle-headr-button .modal-header .btn-close{
    height: 32px;
    width: 32px;
    padding: 0px !important;
    background-color: #FFFFFF !important;
    opacity: 30%;
    border-radius: 32px;
}
