.img-prod-details {
    border-radius: 18px 18px 80px 18px;
}

.od-card {
    border-radius: 18px 18px 80px 18px;
}

.od-merchant-logo {
    position: absolute;
    top: 18px; 
    left: 23px; 
    border-radius: 50%;
    width: 15%; 
    max-width: 64px; 
    height: auto; 
    background-color: white;
  }

.od-card-body {
    padding-left: 30px;
    padding-top: 30px;
}

.od-isn-sub-title{
    margin-bottom: 30px;
}

.of-lbl-validDate{
  padding-bottom: 13px;
}
.od-offer-desscription{
  font-size: 15px !important;
}

.of-detail-font-size{
  font-size: 13px;
}

.od-shareDeals{
  font-size: 12px;
}

.od-tc{
  font-size: 11px;
}

.od-btns-upper{
  font-size: 14px;
}

.od-btns-lower{
  font-size: 12px;
}

.carousel-control-prev-icon-od,
.carousel-control-next-icon-od {
  height: 25px;
  width: 25px;
  outline: white;
  background-size: 100%, 100%;
  border-radius: 50%;
  border: 2px solid white;
  background-image: none;
  background-color: white;
}

.carousel-control-next-icon-od:after
{
  content: '>';
  font-size: 15px;
  color: black;
  background-color: white !important;
}

.carousel-control-prev-icon-od:after {
  content: '<';
  font-size: 15px;
  color: black;
}

.carousel-control-od{
  opacity: 1.5;
}

  @media (max-width: 768px) {
    .od-merchant-logo {
      width: 15%; /* Increase the size on smaller screens if needed */
      max-width: 40px; /* Adjust max-width for smaller screens */
    }
  }