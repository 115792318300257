.modal-content{
    border-radius: 20px;
    
}
.isave-more-modal-header{
    position: absolute;
    right: 0;
    border-bottom: 0px;
}

.isave-more-title{
    color: #5A4394;
    text-align: left;
    padding-left: 15px;
    font-size: 24px;
    font-weight: 500;
    margin-top: 15px;
}

.isave-more-content{
    font-size: 13px;
    line-height: 24px;
    color: #002848;
    padding: 0px 15px 0px 15px;
    text-align: left;
}

.isave-more-content-ul{
    line-height: 22px !important;
}

.isave-more-footer-price{
    font-size: 18px;
    color: #002848;
    line-height: 22px;
    text-align: left;
    float: left;
}

.modal-footer{
    border-top: 0px;
}

.modal-footer{
    border-top: 0px;
    justify-content: left;
}

.isave-more-upgrade{
    background: #D0E518;
    border-radius: 41px;
    height: 46px;
}

.isave-more-upgrade:hover {
    background-color: #D0E518 !important;
}

.isave-more-headr-button .modal-header .btn-close{
    height: 32px;
    width: 32px;
    padding: 0px !important;
    background-color: #FFFFFF !important;
    opacity: 30%;
    border-radius: 32px;
}

.isave-more-content-main{

}