.table-light {
    --bs-table-color: #000;
    --bs-table-bg: #F0F0F0;
    --bs-table-border-color: #c6c7c8;
    --bs-table-striped-bg: #ecedee;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #dfe0e1;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #e5e6e7;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color);
    border-radius: 18px;
}

.ms-n5 {
    margin-left: -40px;
}

.table-body-style{
    font-size: 13px;
}

.table-header-style{
    font-size: 15px;
    font-weight: 450;
}

.btn-light {
    --bs-btn-hover-bg: #fb6e2c;
}
.account-section-heading{
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #1A1A1A;
}

.icon { 
    margin-left: 10px; 
    float: right; 
}
.edit-circle{
    height: 32px;
    width: 32px;
    padding: 5px 7px 0px 7px !important;
    background-color: #F0F0F0;
    border-radius: 32px;
    cursor: pointer;
    color: #212529;
}

.account-form-lable{
    color: #9DA3A3;
    font-size: 11px;
    line-height: 16px;
    font-weight: 600;
}
.account-form-text{
    font-size: 13px;
    line-height: 19.5px;
    font-weight: 400;
    width: auto;
    min-width: 50%;
}
/* .account-form-text::placeholder{
    color : #1A1A1A

} */

.account-check-email{
    font-size: 22px;
    color: rgb(255, 255, 255);
    -webkit-text-stroke-width: 0px;
    background-color:#28D294;
    border-radius: 50%;
    padding: 1px;
    width: 22px;
    height: 22px; 
    justify-content: center;
    display: flex;
}

.form-control-plaintext{
    width: auto;
}

.acount-cancle-sub{
    line-height: 50px;
    font-size: 13px;
    font-weight: 500;
}

.btn-profile-save {
    background-color: #28D294;
    --bs-btn-hover-bg:#28D294;
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    color: #FFFFFF;
    width: 108px;
    height: 46px;
}

.btn-profile-cancle{
    background-color: #E9E9E9;
    --bs-btn-hover-bg:#E9E9E9;
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    color: #9F9F9F;
    --bs-btn-hover-color:#9F9F9F;
    width: 108px;
    height: 46px;
}
.btn-passwword-save{
    width: 188px;
    height: 46px;
    background: #FB6E2C;
    --bs-btn-hover-bg:#FB6E2C;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    --bs-btn-hover-color:#FFFFFF;
}

.show-edit{
    display: block;
}
.hide-edit{
    display: none;
}

#common-profile {
    text-decoration: none;
}

.account-email .form-control{
    color: #A6A6A6 !important;
}
