.btnVerify {
   
    width: 40% !important; /* Adjust or remove this line if you want the button to fill the width of the container */
    /* padding: 10px 20px !important; */
      }
/* 
.btnVerify:hover {

background-color: #e65816 !important;

    }      */
    
/* .button-container-otp {
display: flex;
justify-content: center; /* This will center the button horizontally */
 /* Add some space above the button */


.otp-heading{
    text-align: left;
    color: #002848;
    font-size: 30px;
    font-family: "poopins", serif;
    font-weight: 400 !important;
}

.otp-div-heading{
    display: flex;
justify-content: left;
}
.otp-form-container{
    max-width: 405px !important;
}

.otp-btn-resend-code{
    background-color: white !important;
    color: black !important;
    border: none !important;
}