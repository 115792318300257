  
.signup-pg-terms-link{
  text-decoration: underline;
  font-weight: 500;
  color: black !important;
}

.signup-text-field{
  background-color: white !important;
}

.password-strength-meter {
  width: 100%;
  background-color: #ddd;
  margin-top: 5px;
}

.password-strength-meter-fill {
  height: 5px;
  background-color: green;
  transition: width 0.3s ease-in-out;
}

.btnSignUp {
  background-color: #FB6E2C;
  color:  white;
}


.btnSignUp:hover {
  background-color: #ed7b3e;
}