
.autocomplete {
    /*the container must be positioned relative:*/
    position: relative;
    display: inline-block;
  }
  /* input {
    border: 1px solid transparent;
    background-color: #f1f1f1;
    padding: 10px;
    font-size: 16px;
  }
  input[type=text] {
    background-color: #f1f1f1;
    width: 100%;
  }
  input[type=submit] {
    background-color: DodgerBlue;
    color: #fff;
  } */
  .autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    /* top: 100%; */
    left: 0;
    right: 0;
    max-height: 250px;
    overflow: auto;
  }
  .autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
  }
  .autocomplete-items div:hover {
    /*when hovering an item:*/
    background-color: #e9e9e9;
  }
  .autocomplete-active {
    /*when navigating through the items using the arrow keys:*/
    background-color: DodgerBlue !important;
    color: #ffffff;
  }

  .search-input-placeholder{
    color: #002848;
  }

  /* CSS */
.search-input-placeholder {
  color: #002848 !important; /* Your desired color */
}

/* To specifically style the placeholder */
.search-input-placeholder::placeholder {
  color: #002848 !important; /* Your desired color */
}

@media (max-width: 466px) {
  .hm-search-bar{
    width: 70% !important;
  }
}