.mainHeading {
    font-size: 15px;
    color: #000;
    font-weight: bold;
}

.font-bold{
    font-size: 15px;
    color: #000;
    font-weight: bold;
    flex: none;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    z-index: 1;
}

.flex1{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    z-index: 1;
    display: block;
}

.sub-bullets{
    width: 1%;
}

.accordion-header {
    --bs-accordion-active-bg: #D0E518;
    --bs-accordion-btn-focus-box-shadow: #d8ef1385;
}

.policyHeading {
    font-size: 20px;
    color: #000;
    font-weight: bold;
}