
.btnLogin {
  background-color: #d5e63ef5;
}

.btnLogin:hover {
  background-color: #d0e518;
}

.login-background {
  background-color: #002848;
}

.pgDirectLink{
  color: #FB6E2C !important;
  text-decoration: none;
}

.isave-login-modal-header {
  display: flex;
  justify-content: center; /* Aligns horizontally */
  align-items: center;     /* Aligns vertically if needed */
  margin-top: -15px;
}

.login-text{
color: #d0e518 !important;
}

.modal-header .btn-close {
  background-color: white !important;
  color: black !important;
  border-radius: 20px;
}

@media (max-width: 441px) {
  .isn-log-heading{
    width: 75% !important;
    margin-left: 35px !important;
  }
}


@media (min-width: 442px) and (max-width: 678px) {
  .isn-log-heading{
    width: 65% !important;
    margin-left: 84px !important;
  }
}

@media (min-width: 679px) and (max-width: 764px) {
  .isn-log-heading{
    width: 50% !important;
    margin-left: 152px !important;
  }
}